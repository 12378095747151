<template>
<div>
  <b-row>
<!--    <b-col md="6">-->
<!--      <field-text-->
<!--        fieldId="firstName"-->
<!--        placeholder="Ragione Sociale"-->
<!--        :state="isCompanyNameInvalid"-->
<!--        v-model="company.companyName"-->
<!--      />-->
<!--    </b-col>-->
    <b-col :md="sizeBorder">
      <div>&nbsp;</div>
    </b-col>
    <b-col :md="sizeSection">
      <field-text
        fieldId="companyTaxCode"
        placeholder="Partita IVA"
        :state="isVatNumberInvalid"
        v-model="company.vatNumber"
        :errorMessage="vatNumberError"
      />
    </b-col>
    <b-col :md="sizeSection" v-if="emailMandatory">
      <field-email
        fieldId="companyEmail"
        placeholder="Email persona di riferimento"
        :state="isEmailInvalid"
        v-model="company.email"
        :errorMessage="emailError"
      />
    </b-col>
    <b-col :md="sizeBorder">
      <div>&nbsp;</div>
    </b-col>
  </b-row>
</div>
</template>

<script>
import { isEmail, isPresent, validateVat } from '@/utils/validators';

const FieldText = () => import('@/components/fields/FieldText.vue');
const FieldEmail = () => import('@/components/fields/FieldEmail.vue');

export default {
  name: 'CompanyData',
  components: { FieldText, FieldEmail },
  props: {
    emailMandatory: Boolean,
    company: {
      companyName: String,
      vatNumber: String,
      email: String,
    },
  },
  computed: {
    emailError() {
      if (!isPresent(this.company.email)) {
        return 'Email obbligatoria';
      }
      if (!isEmail(this.company.email)) {
        return 'Email non valida';
      }
      return null;
    },
    vatNumberError() {
      if (!isPresent(this.company.vatNumber)) {
        return 'Partita IVA obbligatoria';
      }
      if (!validateVat(this.company.vatNumber)) {
        return 'Partita IVA non valida';
      }
      return null;
    },
    isCompanyNameInvalid() {
      return isPresent(this.company.companyName);
    },
    isEmailInvalid() {
      return !isPresent(this.emailError);
    },
    isVatNumberInvalid() {
      return !isPresent(this.vatNumberError);
    },
    sizeBorder() {
      return this.emailMandatory ? 2 : 3;
    },
    sizeSection() {
      return this.emailMandatory ? 4 : 6;
    },
  },
  methods: {

  },
};
</script>

<style scoped>

</style>
